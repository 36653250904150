import { useCallback, useMemo, useState } from 'react';

import { RecentSearch, recentSearchesSchema } from 'shared/hooks/use-recent-searches/recentSearchesSchema';

export const MAX_SEARCHES_LENGTH = 10;

export const EXPIRATION_TIME = 30 * 24 * 60 * 60 * 1000; // 30d

const checkDateExpired = (date: string): boolean => {
  const currentDate = new Date();
  const inputDate = new Date(date);

  const timeDifference = currentDate.getTime() - inputDate.getTime();

  return timeDifference < EXPIRATION_TIME;
};

const getRecentSearches = (localStorageKey: string) => {
  const storageData = localStorage.getItem(localStorageKey);
  if (!storageData) {
    return [];
  }

  const validationResult = recentSearchesSchema.array().safeParse(JSON.parse(storageData));
  if (validationResult.error) {
    console.error(`Error in validation recent searches: ${validationResult.error}`);
    return [];
  }

  const recentSearches = Array.from(new Set(validationResult.data.map((item) => item.data.value))).reduce<
    RecentSearch[]
  >((acc, value) => {
    const result = validationResult.data.find((item) => item.data.value === value) as RecentSearch;

    if (acc.length < MAX_SEARCHES_LENGTH && checkDateExpired(result.date)) {
      acc.push(result);
    }

    return acc;
  }, []);

  localStorage.setItem(localStorageKey, JSON.stringify(recentSearches));

  return recentSearches;
};

export const useRecentSearches = (localStorageKey: string) => {
  const [searches, setSearches] = useState<RecentSearch[]>(() => getRecentSearches(localStorageKey));

  const recentSearches = useMemo(() => searches.map(({ data }) => data), [searches]);

  const setRecentSearch = useCallback(
    (data: RecentSearch['data']) => {
      const duplicateSearchIndex = searches.findIndex((item) => item.data.value === data.value);

      const newSearch = { data, date: new Date().toISOString() };

      const newSearches = searches.reduce<RecentSearch[]>(
        (acc, item, index) => {
          if (duplicateSearchIndex !== index && acc.length < MAX_SEARCHES_LENGTH) {
            acc.push(item);
          }

          return acc;
        },
        [newSearch],
      );

      localStorage.setItem(localStorageKey, JSON.stringify(newSearches));

      setSearches(newSearches);
    },
    [localStorageKey, searches],
  );

  return { recentSearches, setRecentSearch };
};
