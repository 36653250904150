import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { useInfinityPlayers } from 'api/player/use-infinity-players/useInfinityPlayers';
import {
  MINIMAL_SEARCH_LENGTH,
  PlayerSearchData,
  PlayerSearchInput,
} from 'features/player-profile/player-search/ui/player-search-input/PlayerSearchInput';
import { routes } from 'kognia/router/routes';
import { useRecentSearches } from 'shared/hooks/use-recent-searches/useRecentSearches';

interface Props {
  width?: number | `${number}${'px' | 'rem' | 'em' | '%' | 'vw'}` | 'auto';
  size?: 'medium' | 'small';
}

const LOCAL_STORAGE_PLAYER_SEARCHES = 'playerRecentSearches';

export const PlayerSearchFeature = ({ width, size = 'medium' }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const { recentSearches, setRecentSearch } = useRecentSearches(LOCAL_STORAGE_PLAYER_SEARCHES);

  const { items, fetchNextPage, isPending, setFilters, setEnabled } = useInfinityPlayers({
    enabled: false,
  });

  const options = useMemo(
    () => items?.map(({ name, id, photoUrl }) => ({ label: name, value: id, photoUrl })) || [],
    [items],
  );

  const handleSearch = useCallback(
    (value: string) => {
      setFilters({ name: value });
      setEnabled(value.length >= MINIMAL_SEARCH_LENGTH);
    },
    [setEnabled, setFilters],
  );

  const handleSelectPlayer = useCallback(
    (playerData: PlayerSearchData) => {
      setRecentSearch(playerData);

      navigate(generatePath(routes.PLAYER_PROFILE, { playerId: playerData.value }));
    },
    [navigate, setRecentSearch],
  );

  return (
    <PlayerSearchInput
      width={width}
      size={size}
      onSelect={handleSelectPlayer}
      onSearch={handleSearch}
      value={searchValue}
      onChange={setSearchValue}
      fetchNextPage={fetchNextPage}
      options={options}
      pastRequestsOptions={recentSearches}
      loading={isPending}
      placeholder={t('explore:input.placeholder')}
    />
  );
};
