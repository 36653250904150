import { useState } from 'react';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { playerTransformer } from 'api/player/use-infinity-players/utils/playerTransformer';
import { generatePlayersUrl } from 'api/routes';
import { SortDirection } from 'shared/types/filters/types';
import { PlayerApiResponse, PlayersApiFilters, Player } from 'shared/types/player/player';

const FETCH_PLAYERS_QUERY_KEY = 'fetchPlayers';

interface Params {
  enabled?: boolean;
}

type PlayersReturnType = InfinityQueryResult<Player, PlayersApiFilters> & {
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useInfinityPlayers = ({ enabled = true }: Params): PlayersReturnType => {
  const [isQueryEnabled, setIsQueryEnabled] = useState<boolean>(enabled);

  return {
    ...useInfinityQuery<Player, PlayersApiFilters, PlayerApiResponse>({
      generateUrl: generatePlayersUrl,
      key: FETCH_PLAYERS_QUERY_KEY,
      options: {
        size: 10,
        sort: 'name',
        sortDirection: SortDirection.DESC,
      },
      initialFilters: {
        name: '',
      },
      transformer: playerTransformer,
      queryOptions: {
        enabled: isQueryEnabled,
      },
    }),
    setEnabled: setIsQueryEnabled,
  };
};
