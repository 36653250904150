import { object, string, z } from 'zod';

export const recentSearchesSchema = object({
  data: object({
    value: string(),
    label: string(),
    photoUrl: string().optional().nullable(),
  }),
  date: string(),
});

export type RecentSearch = z.infer<typeof recentSearchesSchema>;
